<template>
    <div>
        <v-container>
            <v-img
                contain
                class="image-404" src="./../../public/404-image.jpg"
            >
            </v-img>
        </v-container>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {};
    },
    computed: {},
    methods: {},
    mounted() {
        // window.location.pathname = "/";
    },

}
</script>

<style lang="scss">
@import './../../src/css/404.scss';
</style>